import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { AuthServiceService } from '@shared/services/auth-service.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export const authGuardGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthServiceService);
  const router = inject(Router);
  const cookieService = inject(CookieService);

  const targetRoute = state.url; 

  if(cookieService.get('terms') !== 'accepted' && targetRoute !== '/home/profile'){
    router.navigate(['/','home','profile'])
    return of(false)
  }

  if(cookieService.get('habbeas') !== 'accepted' && targetRoute !== '/home/profile'){
    router.navigate(['/','home','profile'])
    return of(false)
  }

  const accessToken = cookieService.get('access_token') || '';

  return from(authService.isUserAuthenticated(accessToken)).pipe(
    map(response => {
      if (response) {
        return true;
      } else {
        router.navigate(['/', 'login']);
        return false;
      }
    }),
    catchError(() => {
      router.navigate(['/', 'login']);
      return of(false);
    })
  );
};
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Console } from 'console';


export const errorCaptureInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  const translateService = inject(TranslateService);
  const router=inject(Router);
  
  return next(req).pipe(catchError((error:HttpErrorResponse)=>{
    let errorMessage:any="";
    try{
      if(error.error instanceof ErrorEvent){
        // errorMessage = `Error: ${error.error.message}`;
        errorMessage =  error.error.message;
      }else if(error.error.message === 'reportLimitReached'){
        translateService.get('others.httpErrorMessages.reportLimit').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }
      else if(error.status >= 500){
        translateService.get('others.httpErrorMessages.internalServerError').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }
      else if (error.status === 401 || error.status === 403) {
        translateService.get('others.httpErrorMessages.expiredSession').subscribe((translation: string) => {
          errorMessage = translation;
        });
        
        router.navigate(['/','landing']);
  
      }else{
        errorMessage = JSON.stringify(error.error);
      }
    }catch(error){
      translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
        errorMessage = translation;
      });
    }


    return throwError(()=> new Error(errorMessage))
  }))

};

// Main app ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

// Imports =====================================================================================================
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { faYoutube, faFacebook, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition, faLocationDot, faEnvelope, faPhone, faCircleExclamation,faTriangleExclamation,faSquareCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
// Interfaces/models ===========================================================================================
import { ISocials } from '@core/models/ISocials';
import { TranslateService } from '@ngx-translate/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveStart, Router } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { CookieService } from 'ngx-cookie-service';
//import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit,OnDestroy {
  // General properties ========================================================================================
  public headerFooter: boolean = false;
  public hideFooter:boolean=false;
  public dangerIcon:IconDefinition = faCircleExclamation;
  public userPermission:any=[];
  public userRole:string='';
  public clickIcon:IconDefinition=faLink;
  public warnningIcon:IconDefinition = faTriangleExclamation;
  public successIcon:IconDefinition = faSquareCheck;
  public loading:boolean=false;
  public locationIcon:IconDefinition=faLocationDot;
  public amountOfLoaders:number=0;
  public emailIcon:IconDefinition=faEnvelope;
  public phoneIcon:IconDefinition=faPhone;
  public allowedPermissions:any={};
  public socials:ISocials[]=[
    {
      name:'esmeraldas-santa-rosa',
      url:'https://www.esmeraldassantarosa.com/',
      icon:faSquareInstagram
    },
    {
      name:'Instagram',
      url:'https://www.instagram.com/esmeraldassantarosa/',
      icon:faSquareInstagram
    },
    {
      name:'Facebook',
      url:'https://www.facebook.com/esmeraldassantarosaa',
      icon:faFacebook
    },
    {
      name:'Youtube',
      url:'https://www.youtube.com/@esmeraldassantarosa2523',
      icon:faYoutube
    }
  ]
  private routerSubscription!: Subscription;
  public miniLoader:boolean=false;
  private alertSubscription!: Subscription;
  private loaderSubscription!: Subscription;
  public alertList:Array<any>=[
    // {fireAlert:true,alertLevel:'ERROR',alertMessage:'Test1',alertDetail:"Esto sucedio porque pepito no lo arregló"},
  ];

  // private popupOpenSubscription!: Subscription;
  // private popupCloseSubscription!: Subscription;
  // private initializingSubscription!: Subscription;
  // private initializedSubscription!: Subscription;
  // private initializationErrorSubscription!: Subscription;
  // private statusChangeSubscription!: Subscription;
  // private revokeChoiceSubscription!: Subscription;
  // private noCookieLawSubscription!: Subscription;

  constructor(private translateService: TranslateService,private router:Router, private alertService:AlertService, private cookieService:CookieService,private renderer: Renderer2, private elRef: ElementRef,/*private ccService: NgcCookieConsentService*/){
    this.translateService.setDefaultLang('ES');
    this.translateService.use(localStorage.getItem('lang') || 'ES');
   
  }

  // LifeCycles ================================================================================================
  ngOnInit(): void {
    this.permissionInitialization();
    this.router.events.subscribe(ev=>{
      if (ev instanceof NavigationStart) {
        this.loading = true;
      } else if (ev instanceof NavigationEnd) {
        this.headerFooter = (!ev.url.includes('/login') && !ev.url.includes('/register'))
        this.hideFooter = ev.url.includes('/legal')
        this.loading = false;
      } else if (ev instanceof NavigationError || ev instanceof NavigationCancel) {
        this.loading = false;
      }
    })

    this.alertSubscription = this.alertService.alert$.subscribe({
      next:(response)=>{
        let fireAlert = true;
        let alertLevel = response.severity;
        let alertMessage = response.message;
        let alertDetail = response.detail;

        this.alertList.push({fireAlert,alertLevel,alertMessage, alertDetail})
      },
      error:(error)=>{
        console.log(error);
        let fireAlert = true;
        let alertLevel = 'ERROR';
        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          let alertMessage = translation;
          this.alertList.push({fireAlert,alertLevel,alertMessage})
        });
      },
      complete:()=>{
        console.log('Suscriber done');
      }

    });

    this.loaderSubscription = this.alertService.loader$.subscribe({
      next:(response)=>{
        this.miniLoader = !this.miniLoader;

      },
      error:(error)=>{
        console.log(error);
        let fireAlert = true;
        let alertLevel = 'ERROR';
        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          let alertMessage = translation;
          this.alertList.push({fireAlert,alertLevel,alertMessage})
        });
      },
      complete:()=>{
        console.log('Suscriber done');
      }
    })

    if(this.cookieService.check('user_permissions')){
      this.userPermission=JSON.parse(this.cookieService.get('user_permissions'));
      this.userRole=this.cookieService.get('user_role');
      this.allowedPermissions['canSeeDetails']=this.userPermission.includes('log_details') || this.userPermission.includes('full_permission')
    }

    /*this.translateService.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
    .subscribe(data => {
      this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
      // Override default messages with the translated ones
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.header = data['cookie.header'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.message = data['cookie.message'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.allow = data['cookie.allow'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.deny = data['cookie.deny'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.link = data['cookie.link'];
      // @ts-ignore: Object is possibly 'null'.
      this.ccService.getConfig().content.policy = data['cookie.policy'];
  
      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages


          // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });
    
    this.initializedSubscription = this.ccService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    })*/

  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
    if(this.loaderSubscription){
      this.loaderSubscription.unsubscribe();
    }

    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializingSubscription.unsubscribe();
    // this.initializedSubscription.unsubscribe();
    // this.initializationErrorSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }
  
  // CustomFuntions ============================================================================================
  closeAlert(alertIndex:number):void{
    if (alertIndex > -1 && alertIndex < this.alertList.length) {
      this.alertList.splice(alertIndex, 1);
    }
  }

  permissionInitialization(){
    
  }



  downloadLegalDoc(legalDoc:any) {
    let filename='legal.docx';

    if(legalDoc === 'A'){
      filename='Política_tratamiento_de_la_información.docx'
    }else if(legalDoc === 'B'){
      filename='Aviso_de_privacidad.docx'
    }else if(legalDoc === 'C'){
      filename='Terminos_y_condiciones_de_uso.docx'
    }

    this.router.navigate(['/','legal'], { queryParams: { doc: legalDoc } });
    // Create an invisible anchor element
    //const link = this.renderer.createElement('a');
    //this.renderer.setAttribute(link, 'href', `/assets/legal/legal${legalDoc}.docx`);
    //this.renderer.setAttribute(link, 'download', filename);

    // Append the anchor to the body and trigger the download
    //this.renderer.appendChild(this.elRef.nativeElement, link);
    //link.click();

    // Remove the anchor element after triggering the download
    // this.renderer.removeChild(this.elRef.nativeElement, link);
  }
}

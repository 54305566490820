<div class="main-app-container">
    
    <!-- Header component -->
    <app-header *ngIf="!loading" [transparentHeader]="headerFooter" [hideLangButton]="!hideFooter" class="main-app-header"></app-header>
    <!-- Content container -->
    <div class="app-content-container">
        <!-- Main app outlet -->
        <div class="app-outlet-container">
            <!-- Loader -->
            <div class="lds-ripple" *ngIf="loading"><div></div><div></div></div>
            <!-- error alert handler -->
            <div class="alerts-container">
                <div *ngFor="let alert of alertList; let idx = index" class="alert" role="alert" 
                     [ngClass]="{
                        'alert-success':alert.alertLevel === 'SUCCESS', 
                        'alert-warning':alert.alertLevel === 'WARNNING',
                        'alert-danger':alert.alertLevel === 'ERROR'
                     }">
                    <div  
                         [ngClass]="{
                            'text-green':alert.alertLevel === 'SUCCESS', 
                            'text-yellow':alert.alertLevel === 'WARNNING',
                            'text-red':alert.alertLevel === 'ERROR'
                         }">
                        <fa-icon class="fa-icon-small" *ngIf="alert.alertLevel === 'ERROR'" [icon]="dangerIcon"></fa-icon>
                        <div *ngIf="alert.alertLevel === 'WARNNING'" class="d-flex">
                            <fa-icon class="fa-icon-small"  [icon]="warnningIcon"></fa-icon>
                            <small>{{'others.generalTranslations.warningDisregard' | translate}}</small>
                        </div>
                        <fa-icon class="fa-icon-small" *ngIf="alert.alertLevel === 'SUCCESS'" [icon]="successIcon"></fa-icon>
                        <p>{{alert.alertMessage}}</p>
                    </div>
            
                    <mat-icon class="close-icon" (click)="closeAlert(idx); $event.stopPropagation()">close</mat-icon>
                    
                    <div class="accordion accordion-flush" [id]="'alert' + idx" *ngIf="alert.alertDetail && allowedPermissions.canSeeDetails">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button" (click)="$event.stopPropagation()" type="button" 
                                        data-bs-toggle="collapse" 
                                        [attr.data-bs-target]="'#collapse' + idx" 
                                        aria-expanded="true" 
                                        [attr.aria-controls]="'collapse' + idx">
                                    <small>{{'others.generalTranslations.seeDetails' | translate}}</small>
                                </button>
                            </h2>
                            <div [id]="'collapse' + idx" class="accordion-collapse collapse" 
                                 [attr.data-bs-parent]="'#alert' + idx">
                                <div class="accordion-body">
                                    <small>{{alert.alertDetail}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- global mini loader container -->
            <div class="miniloader-container">
                <div class="loader-container-mini" *ngIf="miniLoader">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>

            <router-outlet />
        </div>
        
        <!-- Footer container -->
        <div *ngIf="headerFooter && !hideFooter" class="main-app-footer-container">
            <footer class="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- Footer address information -->
                                <address>
                                    <p><span><fa-icon class="fa-icon-small" [icon]="locationIcon"></fa-icon></span>CRA 9 # 113-52 Oficina 1504, Bogotá, Colombia</p>
                                    <div>
                                        <div>
                                            <p><span><fa-icon class="fa-icon-small" [icon]="emailIcon"></fa-icon></span>{{'informacion@esmeraldassantarosa.com'}}</p>
                                        </div>
                                        
                                        <div>
                                            <p class="mr-4 mb-0"><span><fa-icon class="fa-icon-small" [icon]="phoneIcon"></fa-icon></span>+57 (1) 744 53 06 / +57 (1) 703 46 68</p>
                                        </div>
                                    </div>
                                </address>

                                <!-- Footer Socials icons -->
                                <div class="social-icons">
                                    <h6 class="footer-title font-weight-bold">
                                        {{'footer.socials' | translate}}
                                    </h6>
                                    <div class="d-flex">
                                        <ul class="main-app-footer-socials">
                                            <li>
                                                <a href="{{socials[1].url}}">
                                                    <fa-icon class="fa-icon-medium" [icon]="socials[1].icon"></fa-icon>
                                                </a>
                                                <p>{{socials[1].name}}</p>
                                            </li>
                                            <li>
                                                <a href="{{socials[2].url}}">
                                                    <fa-icon class="fa-icon-medium" [icon]="socials[2].icon"></fa-icon>
                                                </a>
                                                <p>{{socials[2].name}}</p>
                                            </li>
                                            <li>
                                                <a href="{{socials[3].url}}">
                                                    <fa-icon class="fa-icon-medium" [icon]="socials[3].icon"></fa-icon>
                                                </a>
                                    
                                                <p>{{socials[3].name}}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="legal-content d-flex flex-column">
                                    <div class="mb-2 d-flex">
                                        <p class="me-2" (click)="downloadLegalDoc('A')">{{'legal.a' | translate}}</p>
                                        <fa-icon class="fa-icon-small" [icon]="clickIcon"></fa-icon>
                                    </div>

                                    <div class="mb-2 d-flex">
                                        <p class="me-2" (click)="downloadLegalDoc('B')">{{'legal.b' | translate}}</p>
                                        <fa-icon class="fa-icon-small" [icon]="clickIcon"></fa-icon>
                                    </div>

                                    <div class="mb-2 d-flex">
                                        <p class="me-2" (click)="downloadLegalDoc('C')">{{'legal.c' | translate}}</p>
                                        <fa-icon class="fa-icon-small" [icon]="clickIcon"></fa-icon>
                                    </div>

                                </div>
                            </div>

                            <!-- Footer more redirections -->
                            <div class="col-sm-6">
                                <div class="row">
                                    <!-- Footer more redirections A -->
                                    <div class="col-sm-4">
                                        <h6 class="footer-title">{{'footer.redirects.a.title' | translate}}</h6>
                                        <ul class="list-footer">
                                            <li><a href="https://blog.esmeraldassantarosa.com/jornada-de-reforestacion-de-en-vereda-santa-rosa/" class="footer-link">{{'footer.redirects.a.links.a' | translate}}</a></li>
                                            <li><a href="https://blog.esmeraldassantarosa.com/premiacion-al-mejor-pesebre-ecologico-de-maripi/" class="footer-link">{{'footer.redirects.a.links.b' | translate}}</a></li>
                                        </ul>
                                    </div>
                                    <!-- Footer more redirections B -->
                                    <div class="col-sm-4">
                                        <h6 class="footer-title">{{'footer.redirects.b.title' | translate}}</h6>
                                        <ul class="list-footer">
                                            <li><a href="https://www.youtube.com/watch?v=Xvujk9IeNgI" class="footer-link">{{'footer.redirects.b.links.a' | translate}}</a></li>
                                            <li><a href="https://www.youtube.com/watch?v=C8XptyJObH8" class="footer-link">{{'footer.redirects.b.links.b' | translate}}</a></li>
                                            <li><a href="https://www.youtube.com/watch?v=A_ow4aiLcUg" class="footer-link">{{'footer.redirects.b.links.c' | translate}}</a></li>

                                        </ul>
                                    </div>
                                    <!-- Footer more redirections C -->
                                    <div class="col-sm-4">
                                        <h6 class="footer-title">{{'footer.redirects.c.title' | translate}}</h6>
                                        <ul class="list-footer">
                                            <li><a href="https://www.esmeraldassantarosa.com/protocolos/1.%20PROGRAMA%20PARA%20LA%20PREVENCION%20DEL%20COVID%2019.pdf" class="footer-link">{{'footer.redirects.c.links.a' | translate}}</a></li>
                                            <li><a href="https://www.esmeraldassantarosa.com/nosotros.html" class="footer-link">{{'footer.redirects.c.links.b' | translate}}</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Copyright footer -->
                <div class="footer-bottom">
                    <div class="container">
                        <div class="d-flex justify-content-between align-items-center footer-bottom-items">
                            <div class="d-flex align-items-center">
                                <p class="mb-0 text-small pt-1"><a href="https://www.esmeraldassantarosa.com/">Esmeraldas Santa Rosa</a> {{'v0.1.0'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

        

    </div>
</div>






import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/services/alert.service';
import { UsersService } from '@shared/services/users-service';
import { Observable, catchError, forkJoin, of } from 'rxjs';

export const sidebarRessolverResolver: ResolveFn<any> = (route, state) => {
  const usersService = inject(UsersService);
  const alertService = inject(AlertService);
  const translateService = inject(TranslateService);

  //If user id is present in the URL
  let urlUserId = null;


  const handleProfileError = (error: any) => {
    translateService.get('others.httpErrorMessages.userProfile').subscribe((translation: string) => {
      const alertMessage = translation;
      alertService.emitAlert(alertMessage, 'ERROR', error);
    });

    return of(null);
  };

  const handlePictureError = (error: any) => {
    // translateService.get('others.httpErrorMessages.userProfilePicture').subscribe((translation: string) => {
    //   const alertMessage = translation;
    //   alertService.emitAlert(alertMessage, 'WARNNING', error);
    // });

    console.log('Image picture not set or unable to fetch');

    return of(null);
  };




  const observableList:{ [key: string]: Observable<any> } = {
    profileGeneralData: usersService.getUserData(urlUserId).pipe(
      catchError(handleProfileError)
    ),
    signedUrl: usersService.getProfilePicture(urlUserId).pipe(
      catchError(handlePictureError)
    )
  };
  

  return forkJoin(observableList);
};

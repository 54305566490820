import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { AuthServiceService } from '@shared/services/auth-service.service';
import { CookieService } from 'ngx-cookie-service';
import { from, of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


export const loginGuardGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthServiceService);
  const router = inject(Router);
  const cookieService = inject(CookieService);

  const accessToken = cookieService.get('access_token') || '';

  return from(authService.isUserAuthenticated(accessToken)).pipe(
    map(response => {
      if (response) {
        router.navigate(['/', 'landing']);
        return false;
      } else {
        return true;
      }
    }),
    catchError(() => {
      return of(true)
    })
  );
};



// Main app routing logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

//Imports ======================================================================================================
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from '@modules/home/pages/home-page/home-page.component';
import { authGuardGuard } from '@shared/guards/auth-guard.guard';
import { loginGuardGuard } from '@shared/guards/login-guard.guard';
import { sidebarRessolverResolver } from '@shared/resolvers/sidebar-ressolver.resolver';
import { userPermissionsResolver } from '@shared/resolvers/user-permissions.resolver';

//Interfaces/models=============================================================================================
//---

//Routing ======================================================================================================
const routes: Routes = [
  //Modules that are loaded into the main APP outlet ============================================================
  {
    //Landing is the welcome general info module
    path:'landing', 
    loadChildren: ()=> import('@modules/landing/landing.module').then(m => m.LandingModule),
    resolve:{userPermission:userPermissionsResolver}
  },

  {
    //Registe is where the user register in cognito and our custom api
    path:'register', 
    loadChildren: ()=> import('@modules/register/register.module').then(m => m.RegisterModule),
    canActivate:[loginGuardGuard],
    resolve:{userPermission:userPermissionsResolver}

  },

  {
    //Registe is where the user register in cognito and our custom api
    path:'legal', 
    loadChildren: ()=> import('@modules/legal/legal.module').then(m => m.LegalModule),
    resolve:{userPermission:userPermissionsResolver}

  },

  {
    //login is where the user logins in cognito and our custom api
    path:'login', 
    loadChildren: ()=> import('@modules/login/login.module').then(m => m.LoginModule),
    canActivate:[loginGuardGuard],
    resolve:{userPermission:userPermissionsResolver}
  },

  {
    //Once loggedIn here is where the user can finally interact with the main functionality
    //It is once again a container with a sidebar and a second router outlet for the content
    path:'home', 
    component:HomePageComponent,
    loadChildren: ()=> import('@modules/home/home.module').then(m => m.HomeModule),
    canActivate:[authGuardGuard],
    resolve:{userPermission:userPermissionsResolver, userData:sidebarRessolverResolver}
  },

  //By default if not route is match user will be redireted to /landing whether the user is logged or not
  {
    path:'**',
    redirectTo:'landing'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

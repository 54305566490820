import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


export const tokenInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  let request = req;

  if(req.headers.has('skipInterceptor')){
      request = request.clone({
        headers:request.headers.delete('skipInterceptor')
      })

      return next(request);
  };

  const cookieService=inject(CookieService);

  const token=cookieService.get('access_token');

  
  if(token){
    request = req.clone({
      setHeaders:{
        authorization: `Bearer ${token}`
      }
    })
  }

  return next(request);
};

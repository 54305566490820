import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { SharedModule } from '@shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { errorCaptureInterceptorInterceptor } from '@shared/interceptors/error-capture-interceptor.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { tokenInterceptorInterceptor } from '@shared/interceptors/token-interceptor.interceptor';
import { MaterialModule } from '@shared/material.module';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
//import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
//import { environment } from '@environ/environment';

registerLocaleData(localeEs,'es');
registerLocaleData(localeEn,'en');

export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: environment.domain,
//   },
//   palette: {
//     popup: {
//       background: '#000'
//     },
//     button: {
//       background: '#f1d600'
//     }
//   },
//   theme: 'edgeless',
//   type: 'opt-out',
//   layout:'basic',
//   showLink:false,
//   autoOpen:true,
// };


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FontAwesomeModule,
    HttpClientModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory:HttpLoaderFactory,
        deps:[HttpClient]
      }
    }),
    // NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    //provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([
      errorCaptureInterceptorInterceptor,
      tokenInterceptorInterceptor
    ])),
    CookieService,
    { provide: LOCALE_ID, useValue: localStorage.getItem('lang')?.toLowerCase() || 'ES'.toLowerCase() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
